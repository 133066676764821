.post-input-container {
    background-color: #ffffff;
    padding: 2rem;
    width: 70%;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  #postContent {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  button.btn {
    background-color: #1e90ff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  button.btn:hover {
    background-color: #0077be;
  }

  #post-btn {
    width: 100%;
  }