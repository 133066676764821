.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 11vh;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}

.header-btn {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #2196F3; /* shade of blue */
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  }

.header-btn:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.9);
}
