.sidebar {
    background-color: rgba(255, 255, 255, 0.69);
    height: 100%;
  }

.rightSideBar {
    top: 15vh;
}

.user-container {
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw;
  flex-direction: column;
  margin: auto;
}

.greeting {
  font-size: 1.2rem;
  margin: auto;
}

.user {
  font-size: 1.5rem;
  font-weight: bold;
  margin: auto;
}

#logout-btn {
  margin-top: 1vh;
}
