#root, #root-container, html, body, .App {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.69);
}

.App {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  justify-content: center;
}



