/* Profile.css */

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .username {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .bio {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }
  
  .social-links {
    display: flex;
    margin-top: 20px;
  }
  
  .social-link {
    margin-right: 10px;
  }
  
  .social-link img {
    width: 30px;
    height: 30px;
  }
  
  .edit-button {
    top: 31px;
    right: 280px;
    border: none;
    color: white;
    cursor: pointer;
    position: relative;
    background-color: #1e90ff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 0.4rem 0.8rem;
    font-size: 0.7rem;
    box-shadow: 0px 4px 0px #000099c4;
    text-shadow: 0px 1px 0px #002d66;
  }
  
  .edit-button:hover {
    background-color: #0077be;
  }
  
  .edit-button:focus {
    outline: none;
  }
  
  .username-input {
    text-align: center;
    font-weight: bold;
    margin-top: 5px;
    width: 85%;
    border-bottom: 1px solid black;
    background-color: transparent;
    padding: 0.1rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    font-size: 24px;
    }

.username-input:focus {
  outline: none;
  border-bottom: 2px solid blue;
}

.bio-input {
    text-align: center;
    font-weight: bold;
    margin-top: 5px;
    width: 150%;
    height: 300px;
    height: 16.75vh;
    border-bottom: 1px solid black;
    background-color: transparent;
    padding: 0.1rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    font-size: 24px;
    }

.bio-input:focus {
  outline: none;
  border-bottom: 2px solid blue;
}

.edit-profile-grid {
  display: grid;
  max-width: 500px;
  grid-template-columns: repeat(3, 150px);
  background-color: #f5f5f5;
  border-radius: 10px;
  z-index: 0;
}

.edit-profile-grid img {
  grid-column: 2/4;
  margin-top: 7px;
}

.edit-profile-grid form {
  grid-column: 1/4;
}

#profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#edit-name-btn {
  background-color: #f5f5f5;;
  border: none;
  color: #1e90ff;
  font-size: 15px;
}

.edit-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.edit-name-container button {
  position: relative;
  left: 20px;
}

.edit-name-container input{
  width: 30%;
}

#name-form {
  width: 100%
}

.edit-bio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#edit-bio-btn {
  background-color: #f5f5f5;;
  border: none;
  color: #1e90ff;
  font-size: 15px;
}

#bio-form {
  width: 100%;
}

#bioInput {
  width: 85%;
  height: 180px;
}

.name-exists {
  color: red;
  width: 100%;
  grid-column: 1/4;
  font-size: 17px;
  position: relative;
  right: 28px;
}

.delete-profile-button {
  position: relative;
  top: 38px;
  right: 280px;
  background-color: red;
  color: white;
  border: none;
  font-size: 10px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 4px 0px #990000;
  text-shadow: 0px 1px 0px #660000;
  cursor: pointer; 
  width: 85px;
  padding: 7.5px;
}

.delete-profile-button:hover {
  background-color: #c24444;
}

.confirm-profile-delete {
  background-color: #f5f5f5;
  width: 50%;
  height: 110px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-profile-delete p {
  margin: 5px;
  font-size: 15px;
}

.really-delete-profile-button {
  background-color: red;
  color: white;
  border: none;
  font-size: 10px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 4px 0px #990000;
  text-shadow: 0px 1px 0px #660000;
  cursor: pointer; 
  width: 85px;
  padding: 7.5px;
}